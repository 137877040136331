import React from "react";

const App = () => {
  return (
    <div className="App">
      <div className="card-style">
        <h1>AAllele Ltd</h1>
        <h2>Coming soon...</h2>
      </div>
    </div>
  );
};

export default App;
